function FitSlider(fitSlider){
	const intervalTime = 5000;
	const touchDiff = 100;

	const prev = fitSlider.querySelector('[data-direction="prev"]');
	const next = fitSlider.querySelector('[data-direction="next"]');
	const wrapper = fitSlider.querySelector('.fit-slider-wrapper');
	const slideCount = wrapper.childElementCount;
	let activeSlide = 0;
	let touchStart = 0;
	// let touchMove = 0;
	let cancelClick = false;
	let sliderIntervalId;

	prev.addEventListener('click', function(){
		activeSlide--;
		if(activeSlide < 0){
			activeSlide = slideCount - 1;
		}
		wrapper.style.transform = `translateX(${activeSlide * -100}%)`;
	})
	next.addEventListener('click', function(){
		activeSlide++;
		if(activeSlide >= slideCount){
			activeSlide = 0;
		}
		wrapper.style.transform = `translateX(${activeSlide * -100}%)`;
	})

	const StartSlider = () => {
		StopSlider();
		sliderIntervalId = setInterval(() => next.click(), intervalTime);
		touchStart = null;
	}

	const StopSlider = () => {
		clearInterval(sliderIntervalId);
	}

	const StartTouch = e => {
		e.preventDefault();
		touchStart = e.clientX || e.touches[0].clientX;
		StopSlider();
	}

	const StopTouch = e => {
		e.preventDefault();

		const touchEnd = e.clientX || e.changedTouches[0].clientX;


		if(Math.abs(touchEnd - touchStart) > touchDiff){
			if(touchEnd > touchStart){
				prev.click();
			}
			else{
				next.click()
			}
			cancelClick = true;
		}
		touchStart = null;
		StartSlider();

	}

	const OnClick = e => {
		const touchEnd = e.clientX || e.touches[0].clientX;
		if(cancelClick){
			e.preventDefault();
		}
		cancelClick = false;
	}

	StartSlider();

	fitSlider.addEventListener('mouseenter', StopSlider);
	fitSlider.addEventListener('mouseleave', StartSlider);

	wrapper.addEventListener('mousedown', StartTouch);
	wrapper.addEventListener('mouseup', StopTouch);

	wrapper.addEventListener('touchstart', StartTouch, {passive: true});
	wrapper.addEventListener('touchend', StopTouch, {passive: true});

	wrapper.addEventListener('click', OnClick);
}

export default FitSlider;
