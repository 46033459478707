import FitSlider from "../../packs_helper/scripts/FitSlider";
import FloatRank from "../../packs_helper/scripts/FloatRank.js";

FloatRank();

const fitSlider = document.querySelector('.fit-slider');
if(fitSlider){
	FitSlider(fitSlider);
}

// let homepageDescShown = false;
// const homepageDesc = document.querySelector("#homepage-desc");
// if (homepageDesc) {
// 	const homepageDescButton = homepageDesc.querySelector("button");
// 	homepageDescButton.addEventListener("click", function () {
// 		const el = homepageDesc.querySelector(".about-section-body");
// 		if (homepageDescShown) {
// 			homepageDescButton.innerText = 'Daha Fazla Göster...';
// 			el.style.maxHeight = '';
// 			setTimeout(() => {
// 				el.style.transition = '';
// 			}, 2000);
// 		} else {
// 			homepageDescButton.innerText = 'Daha Az Göster...';
// 			el.style.transition = `max-height ${el.scrollHeight / 8}ms`;
// 			el.style.maxHeight = el.scrollHeight + "px";
// 		}
// 		homepageDescShown = !homepageDescShown;
// 	});
// }
